import React from 'react';
import Grid from '@material-ui/core/Grid';
import './bloglanding.scss';
import Layout from '../../staticcomponents/layout'
import BlogTile from '../../staticcomponents/BlogTile';
import Container from '@material-ui/core/Container';
import LoadMoreButton from '../../staticcomponents/LoadMoreButton';

export default function BlogLanding({ }) {

  return (
    <>
      <Layout>
        <Grid className="blog-landing-cls">
        <div className='blog-donut-bg'>
        <div className="capsules-bg-right ">
          <Container maxWidth="xl" className='blog-container-div'>
            <Grid container className="blog-container">
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
              <Grid item xl={10} lg={10} md={10} sm={10} xs={10} >
                <Grid className="container-padding">
                  {/* **********************Title******************** */}
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h1>blogs</h1>
                  </Grid>
                  {/* **********************Title******************** */}
                  <Grid container className="atCenter">
                    <BlogTile />
                    <BlogTile />
                    <BlogTile />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
              {/* Load More Butotn Starts */}
             <LoadMoreButton />
                  {/* Load More Butotn Ends */}
            </Grid>
          </Container>
          </div>
          </div>
        </Grid>
      </Layout>
    </>
  );
}

