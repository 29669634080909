import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import './BlogTile.scss';
import { Link } from 'gatsby';
import blog_1 from '../../assets/blog_1.png';
import blog_2 from '../../assets/blog_2.png';
import blog_3 from '../../assets/blog_3.png';
import blog_4 from '../../assets/blog_4.png';

export default function BlogTile({ }) {

  return (
    <>
      {/* ****************short blog******************* */}

    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} tabindex="-1">
        <Link to='#'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}  >
            <Grid className="cols-alignment">
            <Link to='#' tabindex="0">
              <Card className="root" tabindex="-1">
                <CardActionArea tabindex="-1">
                  <Grid className='blog-image'>
                    <CardMedia
                      component="img"
                      image={blog_1}
                      alt="blog title image"/>
                    <Grid className="feature-txt-pos">
                      <p className="featured-txt">Featured</p>
                    </Grid>
                  </Grid>
                  <CardContent className="card-content">
                    <Grid item direction="column" xs={12} md={12} lg={12} xl={12}>
                      <p
                        gutterBottom
                        className="blog-date"> JUNE 16
                      </p>
                      <p
                        gutterBottom
                        className="blog-title">
                          flexible mobility white paper : building budgets
                      </p>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      {/* ****************Podcast Blog******************* */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        tabindex="-1">
        <Link to='#'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className="cols-alignment">
            <Link to='#' tabindex="0">
              <Card className="root" tabindex="-1">
                <CardActionArea tabindex="-1">
                  <Grid className='blog-image'>
                    <CardMedia
                      component="img"
                      image={blog_2}
                      alt="blog title image"/>
                    <Grid className="feature-txt-pos">
                      <p className="featured-txt">Featured</p>
                    </Grid>
                  </Grid>
                  <CardContent className="card-content">
                    <Grid item direction="column" xs={12} md={12} lg={12} xl={12}>
                      <p
                        gutterBottom
                        className="blog-date">
                          JUNE 16
                      </p>
                      <p
                        gutterBottom
                        className="blog-title">
                          expert series: moving in podcast
                      </p>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      {/* ****************Long Blog ******************* */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        tabindex="-1">
        <Link to='#'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className="cols-alignment">
            <Link to='#' tabindex="0">
              <Card className="root" tabindex="-1">
                <CardActionArea tabindex="-1">
                  <Grid className='blog-image'>
                    <CardMedia
                      component="img"
                      image={blog_3}
                      alt="blog title image"/>
                  </Grid>
                  <CardContent className="card-content">
                    <Grid item direction="column" xs={12} md={12} lg={12} xl={12}>
                      <p
                        gutterBottom
                        className="blog-title">
                        mobility supply chain: what’s happening in the wider industry?
                      </p>
                      <p
                        gutterBottom
                        className="blog-date">
                        JUNE 16
                      </p>
                      <p className='blog-description'>
                        Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations.
                      </p>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      {/* ****************Webinar Blog******************* */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        tabindex="-1">
        <Link to='#'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className="cols-alignment">
            <Link to='#' tabindex="0">
              <Card className="root" tabindex="-1">
                <CardActionArea tabindex="-1">
                  <Grid className='blog-image'>
                    <CardMedia
                      component="img"
                      image={blog_4}
                      alt="blog title image"/>
                  </Grid>
                  <CardContent className="card-content">
                    <Grid item direction="column" xs={12} md={12} lg={12} xl={12}>
                      <p
                        gutterBottom
                        className="blog-date">
                        JUNE 16
                      </p>
                      <p
                        gutterBottom
                        className="blog-title">
                          flexible mobility white paper : building budgets
                      </p>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            </Grid>
          </Grid>
        </Link>
      </Grid>

    </>
  );
}
